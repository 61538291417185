<template>
  <div class="section">
    <b-row class="align-items-center mb-2">
      <b-col cols="12" lg="3">
        <div class="d-flex align-items-center">
          <b-input-group>
            <b-form-input v-model="filter.search" placeholder="Search" />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>
        </div>
      </b-col>
      <b-col cols="12" lg="3">
        <b-form-select
          v-model="filter.type"
          :options="options"
        />
      </b-col>
    </b-row>

    <TableSubscription :get-data="getData" :result="result" :detail-result="detailResult" :edit-item="editItem"
      :get-filter="getFilter" @filterBase="getFilter" />
    <ModalSubscription :get-data="getData" :result="result" :detail-result="detailResult" :form-data="formPayload"
      :edit-item="editItem" :update-item="updateItem" :editId="editId" :cleanUpForm="cleanUpForm"
      @getPayload="getPayload" />
  </div>
</template>

<script>
import _ from 'lodash'

import {
  BRow,
  BCol,
  BFormSelect,
  VBToggle,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";

import TableSubscription from "@/components/Subscription/TableSubscription.vue";
import ModalSubscription from "@/components/Subscription/Modal/ModalSubscription.vue";
import HeaderPage from "@/components/HeaderPage.vue";

import { successNotification, errorNotification } from "@/auth/utils";

export default {
  title() {
    return "Subscription";
  },
  components: {
    HeaderPage,
    TableSubscription,
    ModalSubscription,

    BRow,
    BCol,
    BFormSelect,
    VBToggle,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      filter: {
        search: "",
        per_page: 10,
        sort_type: "desc",
        type: null
      },
      formPayload: {
        renewal_name: "",
        renewal_description: "",
        renewal_duration_months: "",
        price: "",
        sell_price: "",
        discount_percent: "",
      },
      editId: null,
      currentPage: 1,
      isLoading: true,
      result: {},
      detailResult: {},
      validations: [],
      options: [
        { value: null, text: 'Please select an option' },
        { value: 'main_subscription', text: 'Main Subcription' },
        { value: 'user_subscription', text: 'User Subscription' },
        { value: 'branch_subscription', text: 'Branch Subscription' },
      ],
    };
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getFilter(value) {
      this.$router.replace({ query: "" });
      this.filter = {
        ...value,
      };
      this.getData();
    },
    async getData(page = 1) {
      this.isLoading = true;
      const queryParams = this.filter;
      queryParams.page = page;
      try {
        this.$store
          .dispatch("subscription/getData", { uuid: "", params: queryParams })
          .then((result) => {
            this.isLoading = false;
            this.result = result.data.data;
            this.currentPage = result.data.data.current_page;
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      } catch (error) {

      }
    },
    getPayload(value) {
      this.formPayload = value;
    },
    cleanUpForm() {
      this.editId = null;
      this.formPayload = {
        renewal_name: "",
        renewal_description: "",
        renewal_duration_months: "",
        price: "",
        sell_price: "",
        discount_percent: "",
      };
    },
    preparePayload() {
      const form = new FormData();
      for (const key in this.formPayload) {
        if (
          this.formPayload.hasOwnProperty(key) &&
          this.formPayload[key] != null
        ) {
          form.append(key, this.formPayload[key]);
        }
      }
      return form;
    },
    editItem(id) {
      this.cleanUpForm();
      this.editId = id;
      this.$store
        .dispatch("subscription/getData", {
          uuid: id,
          params: "",
        })
        .then((result) => {
          this.isLoading = false;
          const data = result.data.data;

          this.formPayload = {
            renewal_name: data.renewal_name,
            renewal_description: data.renewal_description,
            renewal_duration_months: data.renewal_duration_months,
            price: data.price,
            sell_price: data.sell_price,
            discount_percent: data.discount_percent,
            active_discount_period: data.active_discount_period,
          };

          this.$store.commit("subscription/setFormPayload", {
            renewal_name: data.renewal_name,
            renewal_description: data.renewal_description,
            renewal_duration_months: data.renewal_duration_months,
            price: data.price,
            sell_price: data.sell_price,
            discount_percent: data.discount_percent,
            active_discount_period: data.active_discount_period,
          });
          this.$bvModal.show("modal-subscription");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateItem() {
      const form = this.preparePayload();
      this.isLoading = true;
      this.$store
        .dispatch("subscription/postData", {
          params: `/${this.editId}`,
          payload: form,
        })
        .then(async () => {
          await this.getData();
          successNotification(
            this,
            "Success",
            "Subscription berhasil di ubah!"
          );
          this.cleanUpForm();
          this.isLoading = false;
          this.$bvModal.hide("modal-subscription");
          // window.location.href = '/master/users'
        })
        .catch((error) => {
          if (error.response.data.meta.validations) {
            errorNotification(this, "Oops!", error.response.data.meta.messages);
          }
          this.isLoading = false;
        });
    },
  },
};
</script>
